import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import { Link } from 'gatsby'
import privacyPolicy from '../assets/images/privacy-policy.jpg'

const PrivacyPolicy = () => {
    return (
        <Layout pageTitle="Privacy Policy">
            <Navbar />
            <PageBanner
                pageTitle="Privacy Policy"
                homePageText="Home"
                homePageUrl="/"
                activePageText="Privacy Policy"
            />
            <section className="privacy-policy-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="privacy-policy-content">
                                <div>
                                    <h2><span style={{ color: 'rgb(68, 68, 68)' }}>Privacy Policy</span></h2>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>Your privacy is important to us. It is Astute Visions's policy to respect your privacy regarding any information we may collect from you across our website, <a href="https://codejays.com">Astute Visions</a>, and other sites we own and operate.</span></p>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.</span></p>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorised access, disclosure, copying, use or modification.</span></p>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>We don’t share any personally identifying information publicly or with third-parties, except when required to by law.</span></p>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective&nbsp;</span><a href="https://privacypolicies.in/" rel="noopener noreferrer" target="_blank" style={{ color: 'rgb(68, 68, 68)' }}>privacy policies</a><span style={{ color: 'rgb(68, 68, 68)' }}>.</span></p>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</span></p>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</span></p>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }} /></p>
                                    <h2><span style={{ color: 'rgb(68, 68, 68)' }}>Cookie Policy for Astute Visions</span></h2>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>                          </span></p>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>This is the Cookie Policy for Astute Visions, accessible from URL https://www.codejays.com.</span></p>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>                          </span></p>
                                    <h3><span style={{ color: 'rgb(68, 68, 68)' }}>What Are Cookies</span></h3>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>                          </span></p>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>As is common practice with almost all professional websites this site uses cookies, which are tiny files that are downloaded to your computer, to improve your experience. This page describes what information they gather, how we use it and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored however this may downgrade or break certain elements of the sites functionality.</span></p>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>                          </span></p>
                                    <h3><span style={{ color: 'rgb(68, 68, 68)' }}>How We Use Cookies</span></h3>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>                          </span></p>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used to provide a service that you use.</span></p>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>                          </span></p>
                                    <h3><span style={{ color: 'rgb(68, 68, 68)' }}>Disabling Cookies</span></h3>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>                          </span></p>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of this site. Therefore it is recommended that you do not disable cookies.</span></p>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>                          </span></p>
                                    <h3><span style={{ color: 'rgb(68, 68, 68)' }}>The Cookies We Set</span></h3>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>                          </span></p>
                                    <p><br /></p>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>                          </span></p>
                                    <h3><span style={{ color: 'rgb(68, 68, 68)' }}>Third Party Cookies</span></h3>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>                          </span></p>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>In some special cases we also use cookies provided by trusted third parties. The following section details which third party cookies you might encounter through this site.</span></p>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>                          </span></p>
                                    <p><br /></p>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>                          </span></p>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }} /></p>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>                          </span></p>
                                    <p><br /></p>
                                    <p />
                                    <h3><span style={{ color: 'rgb(68, 68, 68)' }}>User's responsibilities</span></h3>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>The user undertakes the responsibility to make appropriate use of the contents and information offered on the site with enunciative, but not imitative, behaviour:</span></p>
                                    <ul>
                                        <li><span style={{ color: 'rgb(68, 68, 68)' }}>A) Not to engage in activities that are illegal or contrary to good faith and public order;</span></li>
                                        <li><span style={{ color: 'rgb(68, 68, 68)' }}>B) Not to spread propaganda or content of a racist, xenophobic, ERROR or gambling nature, any type of illegal pornography, terrorist claims or against human rights;</span></li>
                                        <li><span style={{ color: 'rgb(68, 68, 68)' }}>C) Do not cause damage to physical systems (hardware) and unattainable (software) of Astute Visions, its suppliers or third parties, to introduce or disseminate computer viruses or any other hardware or software systems that are capable of causing damage previously mentioned.</span></li>
                                    </ul>
                                    <h3><span style={{ color: 'rgb(68, 68, 68)' }}>More information</span></h3>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>Hopefully that has clarified things for you and as was previously mentioned if there is something that you aren't sure whether you need or not it's usually safer to leave cookies enabled in case it does interact with one of the features you use on our site.</span></p>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>This policy is effective as of 11 March 2025 21:01.</span></p>
                                </div>

                            </div>
                        </div>

                        {/* <div className="col-lg-4 col-md-12">
                            <aside className="widget-area">
                                <div className="widget widget_insight">
                                    <ul>
                                        <li>
                                            <Link to="/about-us">
                                                About Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">
                                                Contact Us
                                            </Link>
                                        </li>
                                        <li className="active">
                                            <Link to="/privacy-policy">
                                                Privacy Policy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                Terms of Service
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                               <div className="widget widget_recent_courses">
                                    <h3 className="widget-title">Recent Courses</h3>

                                    <article className="item">
                                        <Link to="#" className="thumb">
                                            <span className="fullimage cover bg1" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <span>$49.00</span>
                                            <h4 className="title usmall"><Link to="#">The Data Science Course 2020: Complete Data Science Bootcamp</Link></h4>
                                        </div>
                                        <div className="clear"></div>
                                    </article>

                                    <article className="item">
                                        <Link to="#" className="thumb">
                                            <span className="fullimage cover bg2" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <span>$59.00</span>
                                            <h4 className="title usmall"><Link to="#">Java Programming MasterclassName for Software Developers</Link></h4>
                                        </div>
                                        <div className="clear"></div>
                                    </article>

                                    <article className="item">
                                        <Link to="#" className="thumb">
                                            <span className="fullimage cover bg3" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <span>$69.00</span>
                                            <h4 className="title usmall"><Link to="#">Deep Learning A-Z™: Hands-On Artificial Neural Networks</Link></h4>
                                        </div>
                                        <div className="clear"></div>
                                    </article>
                                </div>

                                <div className="widget widget_tag_cloud">
                                    <h3 className="widget-title">Popular Tags</h3>

                                    <div className="tagcloud">
                                        <Link to="#">Web <span className="tag-link-count"> (3)</span></Link>
                                        <Link to="#">App <span className="tag-link-count"> (3)</span></Link>
                                        <Link to="#">Development <span className="tag-link-count"> (2)</span></Link>
                                        <Link to="#">Software <span className="tag-link-count"> (2)</span></Link>
                                        <Link to="#">Crypto <span className="tag-link-count"> (1)</span></Link>
                                        <Link to="#">Project <span className="tag-link-count"> (1)</span></Link>
                                        <Link to="#">Marketing <span className="tag-link-count"> (1)</span></Link>
                                        <Link to="#">Tips <span className="tag-link-count"> (2)</span></Link>
                                    </div>
                                </div>
                            </aside>
                        </div>
                         */}
                    </div>

                </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default PrivacyPolicy;